import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { TempRegistrationState } from './temp-registration.state';
import { Store } from '@ngrx/store';

import { EffectsBase } from '@store/models';
import { TempRegistrationApi } from '../services/temp-registration-api.service';

@Injectable()
export class TempRegistrationEffects extends EffectsBase<TempRegistrationState> {
  // loadInfo$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(LoadFullInfoActions.init),
  //     switchMap(() => this.api.getFullInfo().pipe(this.handleResponse(LoadFullInfoActions)))
  //   )
  // );

  constructor(
    public override store: Store,
    private actions$: Actions,
    private api: TempRegistrationApi
  ) {
    super(store);
  }
}
