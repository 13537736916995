import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { createInitialState, TempRegistrationState } from './temp-registration.state';
import { TempRegistrationActions } from './temp-registration.actions';
import { AuthActions } from '@features/auth/store';

export const TEMP_REGISTER_FEATURE_KEY: string = 'temp-register';

export const initialState: TempRegistrationState = createInitialState();

type State = TempRegistrationState;

export const tempRegistrationReducer: ActionReducer<TempRegistrationState, Action> = createReducer(
  initialState,

  // ROOT
  on(AuthActions.logout, (): State => initialState),
  on(TempRegistrationActions.root.reset, (): State => initialState),
  on(TempRegistrationActions.root.store, (state, { payload }): State => ({ ...state, form: payload }))
);
