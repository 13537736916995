import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TEMP_REGISTER_FEATURE_KEY } from './temp-registration.reducer';
import { TempRegistrationState } from './temp-registration.state';

const selectState = createFeatureSelector<TempRegistrationState>(TEMP_REGISTER_FEATURE_KEY);

const isFetching = createSelector(selectState, (state) => state.isFetching);
const isProcessing = createSelector(selectState, (state) => state.isProcessing);

const form = createSelector(selectState, (state) => state.form);

export const TempRegistrationSelectors = {
  isFetching,
  isProcessing,
  form,
};
