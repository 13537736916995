import { EntityStateBase } from '@store/models';
import { TempRegistrationFormValue } from '../models/temp-registration.models';

export interface TempRegistrationState extends EntityStateBase {
  form: TempRegistrationFormValue | null;
}

export const createInitialState = (): TempRegistrationState => {
  return {
    isLoaded: false,
    isFetching: false,
    isProcessing: false,
    form: null,
  };
};
