import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TempRegistrationFormValue } from '../models/temp-registration.models';

const PREFIX = '[Temp Registration API]';

const root = createActionGroup({
  source: `${PREFIX} Root`,
  events: {
    reset: emptyProps(),
    store: props<{ payload: TempRegistrationFormValue }>(),
  },
});

export const TempRegistrationActions = {
  root,
};
