import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpApi } from '@core/services/api/http.api';
import { RegistrationStatus, Submission } from '@core/models/entities/registration-form.models';
import { TempRegistrationFormPayload } from '@features/temp-registration/models/temp-registration.models';
import { getTimeZone } from '@shared/utils';

const feature = 'submission';

@Injectable({ providedIn: 'root' })
export class TempRegistrationApi {
  constructor(private readonly httpApi: HttpApi) {}

  checkAbility(): Observable<RegistrationStatus> {
    return this.httpApi.get(`${feature}/guest-check-registrations-status?timezone=${getTimeZone()}`);
  }

  submit(payload: TempRegistrationFormPayload): Observable<Submission> {
    const data = { ...payload, timezone: getTimeZone() };
    return this.httpApi.post(`${feature}/with-family`, data);
  }
}
